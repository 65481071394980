<template>
  <HrdCloudMobileHeader :show-back="true" :show-search="false" title="학습 로드맵">
    <template v-slot:right>
      <HrdCloudMobileRoadmapMenu v-model="showMenu" :include-home="true" />
    </template>
  </HrdCloudMobileHeader>
  <main class="kb-main" id="kb-hrdcloud" v-if="!isLoading">
    <div class="main-content main-component">
      <div v-if="categoryRoadmap.length > 1" class="kb-nav-round-tabs mb-0 py-2">
        <ul class="kb-nav-list">
          <li v-for="(item,idx) in categoryRoadmap" :key="idx" class="kb-nav-item">
            <a class="kb-nav-link" :class="{'is-active': item.hrdCloudRoadmapSn == roadmap.hrdCloudRoadmapSn}" @click="moveToDtl($router, item.hrdCloudCateSn, item.hrdCloudCateLevel)">{{item.hrdCloudCateNm}}-{{item.hrdCloudCateLevelNm}}</a>
          </li>
        </ul>
      </div>
      <div class="roadmap-title">
        <div class="image" :color-no="roadmap.bannerUrl ? -1 : roadmap.hrdCloudRoadmapSn % 5">
          <img v-if="roadmap.bannerUrl" class="h-100 w-100" :src="roadmap.bannerUrl" alt="" loading="eager" />
        </div>
        <div class="title-area">
          <div class="d-flex align-items-center justify-content-center">
            <span class="text">{{roadmap.hrdCloudCateNm}}</span>
            <span v-if="roadmap.cttCmptnCount > 0 && roadmap.cttCmptnCount == cttList.length" class="content-badge">완료</span>
            <span v-else-if="roadmap.cttCmptnCount > 0" class="content-badge progress-badge">진행중</span>
          </div>
          <span class="sub-text">{{roadmap.roadmapTitle}}</span>
        </div>
      </div>
      <article class="content-section">
        <header class="section-header">
          <span class="title">학습 목록</span>
          <div class="d-inline-flex align-items-center">
            <span class="subtitle">{{roadmap.cttCmptnCount}}/{{cttList.length}}</span>
            <i class="ms-2" :class="groupLearningAllPut ? 'icon-interest-active' : 'icon-interest'" v-if="groupLearning.distCrseSn > 0" @click="showGroupLeaderPutModal = !showGroupLeaderPutModal" />
          </div>
        </header>
        <div class="section-body">
          <HrdCloudRow :items="cttList" :session="session" :show-index="true" :show-put="true" />
        </div>
      </article>
      <article class="content-section" v-if="roadmap.roadmapKeyword && false">
        <header  class="section-header">
          <span class="title">AI가 추출한 키워드</span>
        </header>
        <div class="section-body roadmap-content">
          <div class="keyword-list">
            <div class="keyword" v-for="(item,idx) in roadmap.roadmapKeyword.split(',')" :key="idx" :cate-sn="roadmap.hrdCloudCateSn">{{item}}</div>
          </div>
        </div>
      </article>
      <article class="content-section" v-if="roadmap.roadmapNote1 && false">
        <header  class="section-header">
          <span class="title">이런 분들께 추천해요!</span>
        </header>
        <div class="section-body">
          <div class="note" v-html="roadmap.roadmapNote1"></div>
        </div>
      </article>
    </div>
    <HrdCloudRaodmapGroupPutModal v-if="groupLearning.distCrseSn > 0" v-model:show="showGroupLeaderPutModal" :roadmap="roadmap" :cttList="cttList" :group="groupLearning" v-model:allPut="groupLearningAllPut" />
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import HrdCloudMobileRoadmapMenu from '@/components/hrdcloud/mobile/HrdCloudMobileRoadmapMenu';
import HrdCloudRow from "@/components/hrdcloud/HrdCloudRow";
import HrdCloudRaodmapGroupPutModal from '@/components/hrdcloud/HrdCloudRoadmapGroupPutModal.vue';
import {hrdCloudRoadmapPageSetup} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";

export default {
  name: 'MobileHrdCloudRoadmapPage',
  components: {HrdCloudMobileHeader, HrdCloudMobileRoadmapMenu, HrdCloudRow, HrdCloudRaodmapGroupPutModal},
  setup: hrdCloudRoadmapPageSetup
}
</script>