<template>
  <div class="roadmap-menu util util-more">
    <div class="kb-form-layer" :class="{'is-active': modelValue }" >
      <button class="kb-form-layer-btn" @click="toggleMenu"><i class="icon-more"></i></button>
      <div class="layer-container">
        <div class="kb-top-layer" @click.stop.prevent="toggleMenu" />
        <div class="layer">
          <div class="kb-form-layer-options">
            <div class="layer-option">
              <ul class="layer-option-list">
                <li v-if="includeHome" class="layer-option-item">
                  <a class="layer-option-link" @click="moveToHome">
                    <strong class="layer-option-text">학습 로드맵 홈</strong>
                  </a>
                </li>
                <li v-for="(menu,idx) in menus" :key="idx" class="layer-option-item">
                  <a class="layer-option-link" @click="moveToCategory(menu.hrdCloudCateSn, menu.hrdCloudCateLevel)">
                    <strong class="layer-option-text">{{menu.hrdCloudCateNm}}</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import {
  ACT_GET_HRDCLOUD_LEARNING_ROADMAP_MENU
} from "@/store/modules/hrdcloud/hrdcloud";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useRouter} from "vue-router";
import {moveToDtl} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";

export default {
  name: 'HrdCloudMobileRoadmapMenu',
  props: {
    modelValue: Boolean,
    includeHome: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore()
    const router = useRouter()
    const menus = ref([])
    const isLoading = ref(true)

    const toggleMenu = () => {
      emit('update:modelValue', !props.modelValue);
    }

    const moveToHome = () => {
      router.push({name: 'HrdCloudRoadmapMain'});
      toggleMenu();
    }

    const moveToCategory = (cateSn, level) => {
      moveToDtl(router, cateSn, level);
      toggleMenu();
    }

    const getHrdCloudLearningRoadmapList = () => {
      isLoading.value = true;
      store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_MENU}`, null).then(res => {
        if(lengthCheck(res)){
          menus.value = getItems(res);
          isLoading.value = false;
        }
      })
    }

    watch(() => props.modelValue, () => {
      if(props.modelValue && menus.value.length == 0) getHrdCloudLearningRoadmapList();
    })

    return {
      menus,
      toggleMenu,
      moveToHome,
      moveToCategory,
    }
  }
}
</script>